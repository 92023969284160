//@ts-check
import { useEffect, useRef } from "react";
import { useBlocker } from "react-router-dom";

export default function useMyBlocker(message,initialState) {
  var messageShown=useRef(false)
  var shouldBlockRef=useRef(initialState)
  var blocker=useBlocker(shouldBlockRef.current)
  if(blocker.state=='blocked' && !messageShown.current) {
    messageShown.current=true
    setTimeout(()=>{
        if(!shouldBlockRef.current) {
          return blocker.proceed()
        }
        messageShown.current=false
        if(window.confirm(message)) {
          blocker.proceed()
        } else {
          blocker.reset()
        }
      },0)
  }
  useEffect(()=>{
    if(shouldBlockRef.current) {
      window.addEventListener('beforeunload', onUnload)
    }
    return ()=>{
      window.removeEventListener('beforeunload',onUnload)
    }
  },[])

  function onUnload(e){
    if(!shouldBlockRef.current) return
      e.preventDefault()
      var message=message
      e.returnValue = message;
      return message
    }
  return shouldBlockRef
}