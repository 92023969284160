import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
  Link,
} from "react-router-dom";
import "./index.css";
import AdminItem from './admin-item';
import ItemPage from './item-page';

const router = createBrowserRouter([
  {
    path: "/",
    element: <div><Link to={'/admin'}>Admin</Link></div>,
    // element: <div>Hello</div>,
    errorElement:'Page not found'
  },
  {
    path: "/p/:itemId",
    element: <ItemPage/>,
    // element: <div>Hello</div>,
    errorElement:'Page not found'
  },
  {
    path: "/admin",
    element: <App/>,
  },
  {
    path: "/admin/item/:itemId",
        element:<AdminItem/>
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
<App />
root.render(
  <React.StrictMode>
        <RouterProvider router={router} />
  </React.StrictMode>
);
