//@ts-ignore
import TextareaAutosize from 'react-textarea-autosize';
var i=0
export default function MyTextarea({minRows=1,maxRows=10,...props}){
  return <TextareaAutosize 
  minRows={minRows}
  name={'autoname-' + i++}
  {...props}
  style={{
    marginTop:10,
    borderRadius: 11,
    fontSize:19,
    width:'100%',
    boxSizing:'border-box',
    border:'none',
    padding:'14px 16px',
    fontWeight:'bold',
    background: 'radial-gradient(58.05% 77.36% at 50% 50%, #D1D1D1 0%, #BEB7AD 100%)',
    boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.25)',
  }}

  />
}