//@ts-check
import React from "react";
import { Link, useNavigate } from "react-router-dom";

//@ts-check
export default function MyButton({ text, bold = false, onClick = (e) => { }, link = null, theme = '' }) {
  var themes = {
    inline:{
      btnStyle:{
        display:'inline-block',
        color:'blue',
        cursor:'pointer',
      },
    },
    inlineDelete:{
      btnStyle:{
        display:'inline-block',
        color:'red',
        cursor:'pointer',
      },
    },
    default: {
      btnStyle: {
        borderRadius: 11,
        background: 'radial-gradient(58.05% 77.36% at 50% 50%, #D1D1D1 0%, #BEB7AD 100%)',
        boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.25)',
        fontWeight: bold ? 'bold' : '',
        cursor:'pointer',
      },
      textStyle: {
        color: 'black',
        opacity: bold ? 0.8 : 0.66,
        textAlign: 'center',
        fontSize: 20,
        padding: '15px 0px'
      }
    },
    cancel: {
      btnStyle: {
        padding:10,
      },
      textStyle: {
        textAlign: 'center',
        fontSize: 20,
        opacity:0.68,
        cursor:'pointer',
      }
    },
    delete: {
      btnStyle: {
        padding:15,
        cursor:'pointer',
        textAlign:'center',
        fontWeight:'bolder'
      },
      textStyle: {
        fontWeight:400,
        color:'red'
      }
    },
    primary: {
      btnStyle: {
        borderRadius:11,
        padding:15,
        cursor:'pointer',
        background:'black',
        textAlign:'center',
        fontWeight:'bolder'
      },
      textStyle: {
        color:'white'
      }
    }
  }

  var btnStyle = themes[theme || 'default'].btnStyle
  var textStyle = themes[theme || 'default'].textStyle

  var navigate = useNavigate()
  return <div style={btnStyle}
    onClick={(e) => {
      if (onClick) onClick(e)
      if (link) {
        navigate(link)
      }
    }}
  >
    <div style={textStyle}>
      {text}
    </div>
  </div>
}