import { useEffect, useState } from "react"
import { requestJSON } from "./requestJSON"
import useAsyncEffect from "./use-async-effect"

export default function useItems() {
  var [data, set_data] = useState(null)
  var [ready, set_ready] = useState(false)
  useAsyncEffect(async()=>{
      var res=await requestJSON('/items')
      set_ready(true)
      set_data(res)
  },[])
  return {
    data,
    ready,
    refresh: () => { }
  }
}
export function useItem(id) {
  var [data, set_data] = useState(null)
  var [ready, set_ready] = useState(false)
  useAsyncEffect(async()=>{
    if(id=='-1') {
      set_data({
        name: 'שם המונצח',
        content: [
          {
            type:'text',
            id: new Date().valueOf(),
            title: 'כותרת הסיפור',
            text: 'הסיפור המלא'
          }
        ]
      })
    }
    else {
      var res=await requestJSON('/item/' + id)
      set_data({
        ...res,
        id:undefined,
      })
    }
    set_ready(true)
    return
    setTimeout(() => {
      set_ready(true)
      if(id=='-1') {
        set_data({
          name: 'שם המונצח',
          content: [
            {
              id: new Date().valueOf(),
              title: 'כותרת הסיפור',
              text: 'הסיפור המלא'
            }
          ]
        })
        return
      }
      set_data({
          name: 'רבי נחמן מברסלב',
          content: [
            {
              id: new Date().valueOf(),
              title: 'כותרת 1',
              text: 'טקסט 1'
            }
          ]
        },
      )
    }, 100)
  },[])
  return {
    data,
    ready,
    refresh: () => { }
  }
}