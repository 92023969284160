import React from 'react';
import './App.css';
import MyButton from './button';
import useItems from './use-items';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';

function App() {
  var itemsState=useItems()  
  var items=itemsState.data
  if(!itemsState.ready) return <div>...</div>
  return (
    <div style={{
      backgroundImage: "url(/bg.webp)",
      direction:'rtl'
    }}>
      <div style={{
        minHeight:'100svh',
        background:'#CEC9C1',
        maxWidth:1000,
        marginLeft:'auto',
        marginRight:'auto',
      }}>
      <div style={{
        color:'black',
        fontFamily:'Inter',
        // opacity:'0.8',
        fontSize:28,
        fontWeight:'bold',
        paddingTop:44,
        textAlign:'center'
      }}>ניהול דפי הנצחה
      </div>
      <div style={{
        color:'black',
        opacity:'0.8',
        fontSize:18,
        paddingTop:25,
        margin:'0px 30px',
        textAlign:'right'
      }}>בדף זה תוכלו לצפות ברשימת המונצחים, לערוך את פרטי המונצח או להוסיף מונצח חדש.<br/>
      אנו ממליצים להוסיף תמונות ותיאורים מרגשים כדי לשמר את זכרונם בצורה הטובה ביותר.
      </div>
      <div style={{margin:'22px 30px 0px'}}>
        <MyButton link={'item/-1'} text="יצירת דף הנצחה חדש" bold/>
      </div>
      {items.map(item=>
        <div key={item.name} style={{margin:'22px 30px 0px'}}>
          <MyButton 
          link={'item/' + item.id}
          text={item.name}/>
          <div style={{
            textAlign:'center',
            marginTop:10,
            fontSize:18,
            color:'blue',
            cursor:'pointer'
          }}>
          <QRCode value={'netzach.boti.bot/p/' + item.id} height={50}/>
          <br/>
          <Link to={"/p/" + item.id}>
          תצוגה מקדימה
          </Link>
          </div>
          </div>
      )}
    </div>
    </div>
  );
}

export default App;
