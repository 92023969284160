import React from "react";
import { requestJSON } from "./requestJSON";
import { endpoint } from "./endpoint";

var i=0
export default function MyInput(props){
  var name='autoname-' + i++
  var {type}=props
  if(!type) type='text'
  var style={
    marginTop:10,
    borderRadius: 11,
    fontSize:19,
    position:'relative',
    width:'100%',
    boxSizing:'border-box',
    border:'none',
    padding:'14px 16px',
    fontWeight:'bold',
    background: 'radial-gradient(58.05% 77.36% at 50% 50%, #D1D1D1 0%, #BEB7AD 100%)',
    boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.25)',
  }
  async function filechanged(e){
    var base64=await fileToBase64(e.target.files[0])
    e.target.value=''
    var res=await requestJSON('/upload-draft-image','POST',{base64})
    if(res.id && props.onChange){
      props.onChange({target:{name,value:res.id}})
    }
  }
  async function removeImage(){
    props.onChange({target:{name,value:undefined}})
  }
  if(type=="image"){
    return <div>
      {props.value && <div>
        <img src={endpoint+ '/attachments/'+ props.value+ '.png'} style={{width:200}}/>
        <br/>
        &nbsp;<span onClick={removeImage} style={{color:'red',cursor:'pointer'}}> הסר</span>
        </div>}
      <div 
        name={name} 
        //@ts-ignore
        style={style}
        >בחר תמונה<input
          onChange={filechanged}
          type="file"
          accept="image/*"
          name={name}
          style={{
            position:'absolute',
            left:0,
            top:0,
            width:'100%',
            height:'100%',
            opacity:0,
          }}
        /></div>
        </div>
  }
  if(type=="text") {
    return <input
    name={name}
    {...props}
    value={props.value || ''}
    style={style}/>
  }
}

async function fileToBase64(file) {
  return new Promise(resolve=>{
    var reader = new FileReader();
    reader.onload = function(e) {
      var binary = e.target.result;
      resolve(btoa(binary))
    };
    reader.readAsBinaryString(file);
  })
}