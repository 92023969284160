import { endpoint } from "./endpoint"

export async function requestJSON(url,method,body) {
  var body
  if(body) {
    body=JSON.stringify(body)
  }
  var res=await fetch(endpoint + url,{
    method,
    headers:{
      'content-type':'application/json'
    },
    body
  })
  var text=await res.text()
  console.log(text)
  return await JSON.parse(text)
}