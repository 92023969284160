//@ts-check
import { Link, useBlocker, useNavigate, useParams } from "react-router-dom"
import useItems, { useItem } from "./use-items"
import useMyBlocker from "./use-blocker"
import React, { useEffect, useRef, useState } from "react"
import MyInput from "./MyInput"
import MyTextarea from "./MyTextArea"
import Spacer from "./spacer"
import MyButton from "./button"
import { requestJSON } from "./requestJSON"

export default function AdminItem() {
  var { itemId } = useParams()
  var navigate = useNavigate()
  var itemState = useItem(itemId)
  var item = itemState.data
  var [draftData, set_draftData] = useState(null)
  useEffect(() => {
    if (itemState.ready) {
      set_draftData({ ...item })
    }
  }, [itemState.ready])

  var shouldBlockRef = useMyBlocker("האם אתה בטוח כי ברצונך לבטל שינויים?", false)
  var inputs = [
    {
      title: 'שם',
      id: 'name',
      type:'text',
    },
    {
      title: 'תאריך לידה',
      id: 'birth_date',
      type:'text',
    },
    {
      title: 'תאריך פטירה',
      id: 'next_date',
      type:'text',
    },
    {
      title: 'תמונה ראשית',
      id: 'main_image',
      type:'image'
    }
  ]
  function clickCancel() {
    navigate('/admin')
  }
  function updateDraft(evt, id) {
    flagBlock(true)
    set_draftData({
      ...draftData,
      [id]: evt.target.value,
    })
  }
  async function flagBlock(flag) {
    shouldBlockRef.current = flag
  }
  function updateDraftContent(evt, contentId, key) {
    flagBlock(true)
    var newContent = [...draftData.content]
    newContent.find(x => x.id == contentId)[key] = evt.target.value
    set_draftData({
      ...draftData,
      content: newContent
    })
  }
  function addDraftContent(type) {
    var newContent={
      id: new Date().valueOf(),
      type, 
    }
    if(type=='text'){
      newContent={
        ...newContent,
        text: 'תוכן הסיפור', 
        title: 'כותרת' ,
      }
    } else if(type=='image') {
      newContent={ 
        ...newContent,
        image: null, 
        caption:'תיאור התמונה',
      }
    } else if (type=='video') {
      newContent={
        ...newContent,
        video:'https://',
        caption:''
      }
    }
    flagBlock(true)
    set_draftData(prev => {
      return {
        ...prev,
        content: [
          ...prev.content,
          newContent,
        ]
      }
    })
  }
  function deleteContent(id) {
    if(!window.confirm('בטוח?')) return
    flagBlock(true)
    set_draftData(prev => {
      var currentIndex = prev.content.findIndex(x => x.id == id)
      var newContent = [
        ...prev.content.slice(0, currentIndex), // all until before me
        ...prev.content.slice(currentIndex + 1), // all after
      ]
      return {
        ...prev,
        content: newContent
      }
    })
  }
  function moveContent(direction, id) {
    flagBlock(true)
    set_draftData(prev => {
      var currentIndex = prev.content.findIndex(x => x.id == id)
      if (direction == 'up') {
        var newContent = [
          ...prev.content.slice(0, currentIndex - 1), // all until before me
          prev.content[currentIndex],  // me
          prev.content[currentIndex - 1],  //before me
          ...prev.content.slice(currentIndex + 1), // all after
        ]
      } else {
        // down
        var newContent = [
          ...prev.content.slice(0, currentIndex), // Up until one before me
          prev.content[currentIndex + 1], // the next one
          prev.content[currentIndex], // me
          ...prev.content.slice(currentIndex + 2), // all others
        ]
      }
      return {
        ...prev,
        content: newContent
      }
    })
  }
  async function deleteMe() {
    if (!window.confirm('האם ברצונך למחוק מונצח זה?')) return
    var res = await requestJSON('/delete-item/' + itemId, 'DELETE')
    if (res.deleted) {
      flagBlock(false)
      navigate('/admin')
    }

  }
  async function save() {
    var res = await requestJSON('/save-item', 'POST', {
      ...draftData,
      id: itemId,
    })
    if (res.saved) {
      flagBlock(false)
      navigate('/admin')
    }
  }

  if (!itemState.ready || !draftData) return <div>...</div>
  return <div
    style={{ 
      backgroundImage: "url(/bg.webp)",
      minHeight: '100vh', 
      direction: 'rtl' 
    }}>
    <div style={{
        background: '#CEC9C1', 
      maxWidth: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    }}>
      <div style={{
        fontWeight: 'bold',
        fontSize: 21,
        opacity: 0.66,
        paddingTop: 25,
        textAlign: 'center',
      }}>
        <Link style={{ color: 'inherit', textDecoration: 'none' }} to={'/admin'}>&lt; &nbsp;
          {item.name}
        </Link>
      </div>
      <div style={{ margin: '0px 30px' }}>
        {inputs.map(input => {
          return <div key={input.id} style={{
            fontSize: 21,
            marginTop: 14
          }}>{input.title}
            <br />
            <MyInput
              type={input.type}
              value={draftData[input.id]}
              onChange={e => updateDraft(e, input.id)}
            />
          </div>
        })}
        <div style={{
          // fontWeight:'bold',
          fontSize: 23,
          opacity: 0.8,
          paddingTop: 25,
          textAlign: 'center',
        }}>
          פסקאות טקסט סיפורים ותוכן
        </div>
        {draftData?.content.map((contentItem, index, all) =>
          <div key={contentItem.id}>
            {contentItem.type=='image' &&
            <div>
            <MyInput type="image" value={contentItem.imageId} onChange={e => updateDraftContent(e, contentItem.id, 'imageId')} />
            <MyInput type="text" value={contentItem.caption} onChange={e => updateDraftContent(e, contentItem.id, 'caption')} />
              <Spacer height={15} />
              </div>
            }
            {contentItem.type=='video' &&
            <div>
            <MyInput type="text" value={contentItem.video} onChange={e => updateDraftContent(e, contentItem.id, 'video')} />
              <Spacer height={15} />
              </div>
            }
            {contentItem.type=='text' &&
            <div style={{
              // fontWeight:'bold',
              fontSize: 23,
              opacity: 0.8,
              textAlign: 'center',
            }}>
              <MyInput value={contentItem.title} onChange={e => updateDraftContent(e, contentItem.id, 'title')} />
              <Spacer height={5} />
              <MyTextarea minRows={3} value={contentItem.text} onChange={e => updateDraftContent(e, contentItem.id, 'text')} />
          </div>
            }
            <div style={{ textAlign: 'left' }}>

              {index < all.length - 1 && <MyButton text="&#8595;" theme="inline" onClick={() => moveContent('down', contentItem.id)} />}
              <Spacer width={5} />
              {index > 0 && <MyButton text="&#8593;" theme="inline" onClick={() => moveContent('up', contentItem.id)} />}
              <Spacer width={5} />
              <MyButton text={"מחק"} onClick={() => deleteContent(contentItem.id)} theme="inlineDelete" />
            </div>
            <Spacer height={10} />
          </div>
        )}
        <hr/>
          <MyButton bold text={"הוספת פיסקה"} onClick={()=>addDraftContent('text')} />
          <Spacer height={25} />
          <MyButton bold text={"הוספת תמונה"} onClick={()=>addDraftContent('image')}/>
          <Spacer height={25} />
          <MyButton bold text={"הוספת וידאו"} onClick={()=>addDraftContent('video')}/>
        <Spacer height={25} />
        <MyButton onClick={clickCancel} text={"ביטול"} theme="cancel" />
        <Spacer height={25} />
        <MyButton text={"שמירה"} theme="primary" onClick={save} />
        <Spacer height={25} />
        <MyButton text={"מחיקת מונצח"} theme="delete" onClick={deleteMe} />
        <Spacer height={50} />
      </div>
    </div>
  </div>
}