import { useParams } from "react-router-dom"
import { useItem } from "./use-items"
import React from "react"
import { endpoint } from "./endpoint"

export default function ItemPage() {
  var { itemId } = useParams()
  console.log({ itemId })
  var item = useItem(itemId)
  if (!item.ready) return <div>...</div>
  return <div style={{
    backgroundImage: "url(/bg.webp)",
    // fontFamily: 'David Libre',
  }}>
    <div>
      <div style={{
        textAlign: 'right',
        fontSize: 40,
        padding: 40,
        fontWeight: 700,
        position: 'relative',
        background:'black',
        color:'white',
      }}>
        נצח נט
        <div style={{
          position: 'absolute',
          left: 37,
          top: 37,
          width: 50,
          height: 50,
          backgroundImage: 'url(/magen-white.png)',
          backgroundSize:'contain',
        }} />
      </div>
      </div>
    <div style={{
      background: '#BAB6B1',
      maxWidth: 800,
      margin: 'auto',
      minHeight: '100svh',
      paddingBottom:100,
    }}>
      <div style={{
        maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%)'
      }}>
        <img src={endpoint + '/attachments/' + item.data.main_image + '.png'} style={{ width: '100%' }} />
      </div>
      <div style={{
        fontSize: 70,
        textAlign: 'center',
        fontWeight:'bold'
      }}>
        {item.data.name}
        </div>
      <div style={{
        textAlign:'center',
        fontSize:25,
        marginTop:40,
        fontWeight:'bold',
        padding:'0px 30px',
        lineHeight:1.2,
      }}>
        {item.data.birth_date} - {item.data.next_date}
      {item.data.content.map(content=>
      <div key={content.id}>
        {content.type=='text' && <div>
          <div style={{
            fontSize:40,
            marginTop:40,
            textAlign:'right',
          }}>
            {content.title}
            </div>
          <div style={{
          textAlign:'justify',
          direction:'rtl',
          marginTop:10,
          }}>
          {content.text}
          </div>
          </div>}
          {content.type=='image' && <div>
          <div style={{
            fontSize:40,
            marginTop:40,
          }}>
            <img src={endpoint + '/attachments/' + content.imageId + '.png'} style={{width:'100%'}}/>
            </div>
            </div>}
          {content.type=='video' && <div>
          <div style={{
            fontSize:40,
            marginTop:40,
          }}>
            <iframe 
            style={{
              width:'100%',
              aspectRatio:'4/3',
            }}
            src={
              getYouTubeEmbedUrl(content.video)
            }/>
            </div>
            </div>}
            
            <center>
            <div style={{
          left: 37,
          top: 37,
          marginTop:50,
          marginBottom:50,
          opacity:0.3,
          width: 50,
          height: 50,
          backgroundImage: 'url(/magen-white.png)',
          backgroundSize:'contain',
        }} />
        </center>
      </div>
      )}
        </div>
      </div>
    </div>
}


function getYouTubeEmbedUrl(url) {
  let videoId;
  
  // Regular expression to extract the video ID
  const regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
  const match = url.match(regex);
  
  if (match && match[1]) {
      videoId = match[1];
  } else {
      return null; // Return null if no video ID is found
  }

  // Construct the embed URL
  return `https://www.youtube.com/embed/${videoId}`;
}